<template>
  <div class="shadowed-box">
    <div class="clearfix">
      <div class="share-button selected">
        <div class="bottom-5">
          <svg-icon name="upload" class="base-icon"></svg-icon>
        </div>

        <div class="tiny-header-text">
          Upload From Device
        </div>
      </div>
    </div>

    <loading-section name="documentSharing" :render-after-loading="true" class="padded">
      <upload-sharer
        :is-lawyer="isLawyer"
        :projects="projects"
        :project-id="projectId"
        :on-share-documents="shareDocuments">
      </upload-sharer>
    </loading-section>
  </div>
</template>

<script>
import UploadSharer from 'vue-app/marketplace/shared/documents/upload-sharer.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import Document from 'resources/document.js';
import Matter from 'resources/marketplace/matter.js';

export default {
  name: 'DocumentSharingEditor',

  components: {
    UploadSharer,
    SvgIcon,
    LoadingSection
  },

  props: {
    isLawyer: {
      type: Boolean,
      required: true
    },

    projectId: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      shareMethod: 'Priori',
      projects: [],
      connectedApis: {}
    };
  },

  created() {
    LoadingService.loading('documentSharing');

    Matter.query({ open: true }).then(projects => {
      this.projects = projects;

      LoadingService.done('documentSharing');
    });
  },

  methods: {
    shareDocuments(documents, matterId, documentSource) {
      return Document.bulkCreate({ documents, matterId, documentSource })
        .then(() => {
          NotificationService.success('Successfully shared documents.', true);
          window.location.href = `/${this.isLawyer ? 'lawyer-app' : 'client-app'}/projects/${matterId}`;
        })
        .catch(() => {
          NotificationService.error('There were errors sharing the documents.', true);
          window.location.reload();
        });
    }
  }
};
</script>

<style scoped lang="scss">
  :deep(.padded .loading-modal) {
    padding: 10px 0 20px;
  }
</style>
