<template>
  <div class="normal-weight size-text-13px">
    <div class="box-content symmetrical" v-if="!selectedFiles.length && !invalidFiles.length">
      <file-selector
        :multiple="true"
        :accept="acceptedExtensions"
        @change="handleFileChange">
        <div class="select-file-box top-30 bottom-30">
          <div class="bottom-15">
            <svg-icon name="upload" class="base-icon"></svg-icon>
          </div>

          <div class="semibold-weight size-text-13px">
            Select files to upload from your device
          </div>
        </div>
      </file-selector>
    </div>

    <div v-if="selectedFiles.length || invalidFiles.length">
      <form class="a-form" novalidate>
        <div class="box-content symmetrical">
          <div class="left-30-not-xs right-30-not-xs">
            <div class="row tight-columns dark-gray-text semibold-weight bottom-10 hidden-xs">
              <div class="col-sm-7">
                File Name
              </div>

              <div class="col-sm-5">
                Note
              </div>
            </div>

            <div class="bottom-15">
              <hr class="kenny-hr no-margin">
            </div>

            <div v-for="file in selectedFiles" :key="file.name">
              <div class="row tight-columns vertical-center-not-xs">
                <div class="col-sm-7 clearfix">
                  <div class="pull-left right-20">
                    <radial-progress-indicator :progress-object="file"></radial-progress-indicator>
                  </div>

                  <div class="pull-left top-10 semibold-weight">
                    {{ file.name }}
                  </div>
                </div>

                <div class="col-sm-3 top-20-xs">
                  <input
                    v-if="file.document"
                    type="text"
                    class="form-control"
                    v-model="file.document.note"
                    placeholder="Add a note here...">
                </div>

                <div class="col-sm-2 text-right-not-xs">
                  <button type="button" class="nv-button-square-white a-button-size" @click="removeFile(file)" :disabled="fileUploading(file)"><svg-icon name="trash" class="base-icon"></svg-icon></button>
                </div>
              </div>

              <div class="top-15 bottom-15">
                <hr class="kenny-hr no-margin">
              </div>
            </div>

            <div v-for="file in invalidFiles">
              <div class="row tight-columns vertical-center-not-xs">
                <div class="col-sm-10 clearfix">
                  <div class="pull-left right-20">
                    <div class="radial-progress-error">
                      <svg-icon name="x3" class="base-icon"></svg-icon>
                    </div>
                  </div>

                  <div class="pull-left top-10 semibold-weight">
                    {{ file.name }}
                  </div>
                </div>

                <div class="col-sm-2 text-right-not-xs">
                  <button type="button" class="nv-button-square-white" @click.prevent="removeInvalidFile(file)">
                    <svg-icon name="trash" class="base-icon"></svg-icon>
                  </button>
                </div>
              </div>

              <div class="top-15 bottom-15">
                <hr class="kenny-hr no-margin">
              </div>
            </div>

            <file-selector
              :multiple="true"
              :accept="acceptedExtensions"
              :disabled="sharing"
              @change="handleFileChange">
              <span class="pseudo-link-blue semibold-weight">+ Add Files</span>
            </file-selector>

            <div class="row top-30">
              <div class="col-sm-8 col-md-6">
                <label for="selected-project">Select {{ isLawyer ? 'Client' : 'Lawyer' }}/Project <span class="inline-help required">*</span></label>

                <dropdown-select
                  id-label="selected-project"
                  label-key="label"
                  value-key="value"
                  :initial-label="projectLabel(selectedProject)"
                  :placeholder="`Select ${ isLawyer ? 'Client' : 'Lawyer' }/Project`"
                  :options="projectOptions"
                  @input="(projectId) => selectProject(projectId)">
                </dropdown-select>
              </div>
            </div>
          </div>
        </div>
      </form>

      <hr class="kenny-hr no-margin">

      <div class="box-content">
        <div class="row top-10">
          <div class="col-sm-4 col-sm-offset-4 bottom-10">
            <button type="button" class="nv-button-purple-red smaller" @click="shareDocuments" :disabled="submitDisabled">
              Share Documents <span class="spinner left-5" v-show="sharing"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressIndicator from 'vue-app/shared/components/radial-progress-indicator.vue';
import FileSelector from 'vue-app/shared/components/file-selector.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import Document from 'resources/document.js';
import { orderBy, each, map, some, omit, partition } from 'lodash';

export default {
  name: 'UploadSharer',

  components: {
    RadialProgressIndicator,
    FileSelector,
    SvgIcon,
    DropdownSelect
  },

  props: {
    isLawyer: {
      type: Boolean,
      required: true
    },

    projectId: {
      type: String,
      required: false
    },

    projects: {
      type: Array,
      required: true
    },

    onShareDocuments: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      acceptedExtensions: '.jpg, .jpeg, .gif, .png, .bmp, .pdf, .doc, .docx, .key, .xls, .xlsx, .csv, .txt, .ppt, .pptx, .pps, .ppsx, .odt, .zip, .mp3, .m4a, .ogg, .wav, .mp4, .m4v, .mov, .avi, .mpg, .ogv, .3gp, .3g2',

      selectedFiles: [],

      selectedProject: null,

      sharing: false,

      invalidFiles: []
    };
  },

  computed: {
    orderedProjects() {
      if (this.isLawyer) {
        return orderBy(this.projects, [this.companyOrClientName, 'nameForLawyersReference'], ['asc', 'asc']);
      }
      else {
        return orderBy(this.projects, [this.lowerCaseLawyerName, 'nameForClientsReference'], ['asc', 'asc']);
      }
    },

    projectOptions() {
      return this.orderedProjects.map((project) => {
        return {
          label: this.projectLabel(project),
          value: project.id
        };
      });
    },

    uploadedDocuments() {
      const files = this.selectedFiles.filter((file) => file.document);
      return map(files, function (f) { return f.document; });
    },

    submitDisabled() {
      return !this.selectedProject || !this.selectedFiles.length || this.sharing || this.isUploading;
    },

    isUploading() {
      return some(this.selectedFiles, function (file) { return file.progress < 100; });
    }
  },

  created() {
    this.selectProject(this.projectId);
  },

  methods: {
    handleFileChange(files) {
      const vueInstance = this;
      const maxSize = Math.round(50 * Math.pow(1024, 2)); //50 MB

      const [valid, invalid] = partition(files, (file) => (file.size <= maxSize));

      each(valid, function (file) {
        vueInstance.uploadFile(file);
      });

      vueInstance.invalidFiles = [];

      each(invalid, function (file) { vueInstance.invalidFiles.push(file); });
    },

    uploadFile(file) {
      const uploadTracker = { name: file.name, progress: 0, document: null };
      const vueInstance   = this;

      vueInstance.selectedFiles.push(uploadTracker);

      const onUploadProgress = (progressEvent) => {
        uploadTracker.progress = Math.min(100, parseInt(99.0 * progressEvent.loaded / progressEvent.total));
      };

      Document.upload(file, onUploadProgress)
        .then((document) => {
          uploadTracker.progress += 1;
          uploadTracker.document = document;
        })
        .catch(() => {
          vueInstance.selectedFiles = vueInstance.selectedFiles.filter((file) => file !== uploadTracker);
          vueInstance.invalidFiles.push(file);
        });
    },

    selectProject(projectId) {
      this.selectedProject = this.projects.find((project) => project.id === parseInt(projectId));
    },

    projectLabel(project) {
      if (!project) { return null; }

      if (this.isLawyer) {
        return `${project.client.businessName || project.client.fullName} (${project.nameForLawyersReference})`;
      }
      else {
        return `${project.lawyer.fullName} (${project.nameForClientsReference})`;
      }
    },

    shareDocuments() {
      if (!this.submitDisabled) {
        this.sharing = true;

        this.onShareDocuments(this.uploadedDocuments, this.selectedProject.id, 'Priori')
          .finally(() => {
            this.sharing = false;
          });
      }
    },

    removeFile(file) {
      this.selectedFiles = this.selectedFiles.filter((f) => f !== file);
      file = omit(file, 'document');
    },

    removeInvalidFile(file) {
      this.invalidFiles = this.invalidFiles.filter((f) => f !== file);
    },

    companyOrClientName(matter) {
      return (matter.client.businessName || matter.client.fullName).toLowerCase();
    },

    lowerCaseLawyerName(matter) {
      return matter.lawyer.fullName.toLowerCase();
    },

    fileUploading(file) {
      return file.progress < 100;
    }
  }
};
</script>

<style lang="scss" scoped>
  :deep(.file-select) {
    width: 100%;
    cursor: pointer;
  }
</style>
