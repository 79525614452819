<template>
  <div :class="['radial-progress', { 'completed': progressComplete }]">
    <div class="circle">
      <div class="mask full" :style="rotationStyles(progressDegrees)">
        <div class="fill" :style="rotationStyles(progressDegrees)"></div>
      </div>

      <div class="mask half">
        <div class="fill" :style="rotationStyles(progressDegrees)"></div>
        <div class="fill fix" :style="rotationStyles(progressDegrees * 2)"></div>
      </div>
    </div>

    <div class="inset">
      <div class="progress-percent" v-show="!progressComplete">
        {{ progressObject.progress }}%
      </div>

      <div class="complete-success" v-show="progressComplete">
        <svg-icon name="check-small" class="base-icon"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'src/vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RadialProgressIndicator',

  components: {
    SvgIcon
  },

  props: {
    progressObject: {
      type: Object,
      required: true
    }
  },

  computed: {
    progressComplete() {
      return this.progressObject.progress === 100;
    },

    progressDegrees() {
      return (180 / 100) * this.progressObject.progress;
    }
  },

  methods: {
    rotationStyles(degrees) {
      return `
        -webkit-transform: rotate(${degrees}deg);
        -ms-transform': rotate(${degrees}deg);
        transform: rotate(${degrees}deg);
      `;
    }
  }
};
</script>
